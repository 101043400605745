import { memo, useEffect } from "react";
import "vanilla-cookieconsent";
import "./styles.scss";
import { getLocalizedVal } from "~/tools/localization/helpers";

const GenericMessage = {
  cookieModalDescription: "This website uses cookies to ensure you get the best experience on our website.",
  requiredCookieAccordionTitle: "Strictly Necessary Cookies",
  requiredCookieAccordionDescription: "These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work.",
  optionalCookieAccordionTitle: "Targeting Cookies ",
  optionalCookieAccordionDescription: "These cookies collect information about your online interactions in order to build a profile of your interests and show you relevant ads on third-party sites, including on social media services. If you opt-out of these cookies, you will still receive ads but they may be less personalized.",
  functionalCookieTitle: "Functional Cookies",
  functionalCookieDescription: "These cookies enable the website to provide enhanced functionality and personalized content.",
  performanceCookieTitle: "Performance Cookies",
  performanceCookieDescription: "These cookies allow us to measure metrics like visits and traffic sources so we can measure and improve the performance of our sites. For example, they help us to know which pages are the most and least popular and see how visitors move around the site.",
  cookieAcceptBtnLabel: "Accept All",
  cookieRejectBtnLabel: "Reject All",
  cookieSaveSettingsBtnLabel: "Save Changes",
  cookieSettingsModalTitle: "Your Privacy",
  cookieSettingsModalDescription: "When you visit our website, we and our service providers and partners may use cookies and pixels (also known as web beacons) (collectively, “cookies”) to collect and use information for the purposes of delivering the website experience, understanding how visitors interact with our site and personalizing our advertising. This information might be about you, your preferences, or your device. For relevant sites, you can choose not to allow some types of cookies that are set by us and certain third parties that provide analytics, functionality, and advertising.<br /><br />Click on the different category headings to find out more and change the default settings, although please note that blocking some types of cookies may impact your experience and the services you receive through relevant sites. In addition, these choices will be specific to the device and browser you are using.<br /><br />Please review our Cookie Policy for information about our privacy practices and additional choices available to you.",
  cookieSettingsMoreInfoTitle: "More Information ",
  cookieSettingsMoreInfoDescription: "For any queries in relation to our policy on cookies and your choices. ",
  contactUsLinkLabel: "contact us"
};

// const analyticsServices = [
//   "https://www.google-analytics.com",
//   "https://matomo.org/piwik.js",
//   "https://assets.adobedtm.com/",
//   "https://api.mixpanel.com",
//   "https://cdn.heapanalytics.com",
//   "https://cdn.segment.com",
//   "https://script.hotjar.com",
//   "https://cdn.crazyegg.com",
//   "https://connect.facebook.net",
//   "https://analytics.twitter.co",
//   "https://www.googletagmanager.com",
//   "http://static.clevertap.com",
//   // Add more tracking services as needed
// ];
// const CookieLevelMap = {
//   requiredCookies: [],
//   optionalCookies: ["_ga", "_fbp", "_gid"],
// };
// /**
//  * isThirdPartyScript
//  * @param {String} src
//  * @returns {Boolean}
//  */
// function isThirdPartyScript(src) {
//   return analyticsServices.some((service) => src.includes(service));
// }
// /**
//  * detectThirdPartyScripts
//  * @returns {String[]}
//  */
// function detectThirdPartyScripts() {
//   const scriptElements = document.querySelectorAll("script");
//   const detectedScripts = [];
//   scriptElements.forEach((script) => {
//     if (script.src && isThirdPartyScript(script.src)) {
//       detectedScripts.push({ src: script.src });
//     }
//   });

//   return detectedScripts;
// }
function getCookieListFromBrowser() {
  return document.cookie.split(";");
}

export default memo(function CookieConsentV2() {
    /**
     * @type {UserConfig}
    */
   const privacyPolicyLinkUrl = (window.settings.privacy && window.settings.privacy.vlCookiesConsent && window.settings.privacy.vlCookiesConsent.privacyPolicyLinkUrl) || null

   const privacyPolicyAnchorUrl = privacyPolicyLinkUrl ? ` <a href=${privacyPolicyLinkUrl} class="cc-link">${getLocalizedVal(window.settings, "genericMessages", "privacyPolicyLinkLabel") || 'privacy policy'}</a>.` : ''

   const contactUsLinkUrl = (window.settings.privacy && window.settings.privacy.vlCookiesConsent && window.settings.privacy.vlCookiesConsent.contactUsLinkUrl) || null

   const contactUsAnchorUrl = contactUsLinkUrl ? ` <a class="cc-link" href="${contactUsLinkUrl}">${getLocalizedVal(window.settings, "genericMessages", "contactUsLinkLabel") || GenericMessage.contactUsLinkLabel}</a>.` : ''

    const pluginConfig = {
        current_lang: "en",
        autoclear_cookies: true, // default: false
        page_scripts: true, // default: false
        mode: 'opt-out',                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
        delay: 0, // default: 0
        auto_language: "browser", // default: null; could also be 'browser' or 'document'
        // autorun: true,                          // default: true
        force_consent: true, // default: false
        // hide_from_bots: false,                  // default: false
        remove_cookie_tables: false, // default: false
        // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
        // cookie_expiration: 182,                 // default: 182 (days)
        // cookie_necessary_only_expiration: 182   // default: disabled
        // cookie_domain: window.location.hostname,       // default: current domain
        // cookie_path: '/',                       // default: root
        // cookie_same_site: 'Lax',                // default: 'Lax'
        // use_rfc_cookie: false,                  // default: false
        // revision: 0,                            // default: 0
    
        onFirstAction: function(user_preferences, cookie) {
        // console.log({ user_preferences, cookie });
            const accepted_categories = user_preferences.accepted_categories; 
            if(accepted_categories.includes('optional') || accepted_categories.includes('performanceCookie')){
                window.location.href = window.location.href
            }
        // callback triggered only once
        const analyticsEnabled = window.CC.allowedCategory("analytics");
        // get all scripts related to optional cookie name and add data-cookiecategory attr
        },
    
        onAccept: function(cookie) {
        // console.log({ cookie });
        // if optional cookies are blocked, block all related scripts
        // by removing data-cookiecategory from related script tag
        // else do nothing
        // ...
        },
    
        onChange: function(cookie, changed_preferences) {
        // console.log({ cookie, changed_preferences });
        // if permission changes for optional cookies,
        // update the permissions for related scripts as well
        // find related <script> tag in the DOM and add/remove attr data-cookiecategory from script
        // ...
        },
        gui_options: {
        consent_modal: {
            // layout: layoutType || "box", // box/cloud/bar
            layout: "cloud", // box/cloud/bar
            position: "middle", // bottom/middle/top + left/right/center
            transition: "slide", // zoom/slide
            swap_buttons: false, // enable to invert buttons
        },
        settings_modal: {
            layout: "box", // box/bar
            position: "middle", // left/right
            transition: "slide", // zoom/slide,
        },
        },
        languages: {
        en: {
            consent_modal: {
            title: `${getLocalizedVal(window.settings, "genericMessages", "cookieModalHeadingTitle") ||
            "We use cookies!"}`,
            description: `${getLocalizedVal(window.settings, "genericMessages", "cookieModalDescription") ||
                "This website uses cookies to ensure you get the best experience on our website."} <button type="button" data-cc="c-settings" class="cc-link">${getLocalizedVal(window.settings, "genericMessages", "cookieLetMeChooseButtonLabel") || 'Cookies Settings'}</button>`,
            primary_btn: {
                text: `${getLocalizedVal(window.settings, "genericMessages", "cookieAcceptBtnLabel") || GenericMessage.cookieAcceptBtnLabel}`,
                role: "accept_all", // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
                text: `${getLocalizedVal(window.settings, "genericMessages", "cookieRejectBtnLabel") || GenericMessage.cookieRejectBtnLabel} `,
                role: "accept_necessary", // 'settings' or 'accept_necessary'
            },
            },
            settings_modal: {
            title: `${getLocalizedVal(window.settings, "genericMessages", "cookieLetMeChooseButtonLabel") ||
            "Cookie Settings"}`,
            save_settings_btn: `${getLocalizedVal(window.settings, "genericMessages", "cookieSaveSettingsBtnLabel") || GenericMessage.cookieSaveSettingsBtnLabel} `,
            accept_all_btn: `${getLocalizedVal(window.settings, "genericMessages", "cookieAcceptBtnLabel") || GenericMessage.cookieAcceptBtnLabel} `,
            reject_all_btn: `${getLocalizedVal(window.settings, "genericMessages", "cookieRejectBtnLabel") || GenericMessage.cookieRejectBtnLabel} `,
            close_btn_label: "Close",
            cookie_table_headers: [{ col1: "Name" }, { col2: "Value" }],
            blocks: [
                {
                title: `${getLocalizedVal(window.settings, "genericMessages", "cookieSettingsModalTitle") || GenericMessage.cookieSettingsModalTitle}`,
                description: `${getLocalizedVal(window.settings, "genericMessages", "cookieSettingsModalDescription") || GenericMessage.cookieSettingsModalDescription} ${privacyPolicyAnchorUrl}`,
                    // toggle: {
                    //     value: "Your Privacy",
                    //     enabled: false,
                    //     readonly: false,
                    // },
                },
                {
                title: `${getLocalizedVal(window.settings, "genericMessages", "requiredCookieAccordionTitle") || GenericMessage.requiredCookieAccordionTitle} `,
                description: `${getLocalizedVal(window.settings, "genericMessages", "requiredCookieAccordionDescription") || GenericMessage.requiredCookieAccordionDescription} `,
                    toggle: {
                        value: "necessary",
                        enabled: true,
                        readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                    },
                },
                // {
                //     title: `${getLocalizedVal(window.settings, "genericMessages", "functionalCookieTitle") || GenericMessage.functionalCookieTitle} `,
                //     description: `${getLocalizedVal(window.settings, "genericMessages", "functionalCookieDescription") || GenericMessage.functionalCookieDescription} `,
                //     toggle: {
                //         value: "functionalCookie", // your cookie category
                //         enabled: true || null,
                //         readonly: false,
                //     },
                // },
                {
                    title: `${getLocalizedVal(window.settings, "genericMessages", "performanceCookieTitle") || GenericMessage.performanceCookieTitle} `,
                    description: `${getLocalizedVal(window.settings, "genericMessages", "performanceCookieDescription") || GenericMessage.performanceCookieDescription} `,
                    toggle: {
                        value: "performanceCookie",
                        enabled: true || null,
                        readonly: false,
                    },
                },
                {
                    title: `${getLocalizedVal(window.settings, "genericMessages", "optionalCookieAccordionTitle") || GenericMessage.optionalCookieAccordionTitle} `,
                    description: `${getLocalizedVal(window.settings, "genericMessages", "optionalCookieAccordionDescription") || GenericMessage.optionalCookieAccordionDescription} `,
                    toggle: {
                        value: "optional", // your cookie category
                        enabled: true || null,
                        readonly: false,
                    },
                    // cookie_table: CookieLevelMap.optionalCookies.map((_optional) => ({
                    //   is_regex: true,
                    //   col1: `^${_optional}`,
                    //   col2: getCookieListFromBrowser().find((_item) => (_item.includes(_optional) ? _item : undefined)),
                    // })),
                },
                {
                title: `${getLocalizedVal(window.settings, "genericMessages", "cookieSettingsMoreInfoTitle") || GenericMessage.cookieSettingsMoreInfoTitle} `,
                description:
                `${getLocalizedVal(window.settings, "genericMessages", "cookieSettingsMoreInfoDescription") || GenericMessage.cookieSettingsMoreInfoDescription} ${contactUsAnchorUrl}`
                },
            ],
            },
        },
        },
    };
    useEffect(() => {
        // if (window) {
        //     detectThirdPartyScripts();
        // }
        if (document.getElementById("root")) {
            window.CC = window.initCookieConsent();
            window.CC.run({ ...pluginConfig });
        }
        return () => {};
    }, []);

    return null;
});
